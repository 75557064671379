.css-g1d714-ValueContainer:focus,
.css-2b097c-container:focus,
.css-yk16xz-control:focus,
.css-1pahdxg-control,
.css-1pahdxg-control:hover {
  border: 1px solid #000000 !important;
  box-shadow: none !important;
  outline: none !important;
}

.css-1pahdxg-control:hover {
  border-color: #000000 !important;
}

.css-yk16xz-control {
  border: 1px solid #000000 !important;
}

.css-g1d714-ValueContainer {
  padding: 9.5px 20px !important;
}

.custom-class .css-yk16xz-control {
  border: 1px solid red !important;
}
