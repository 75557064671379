/* Custom CSS Dependencies */
@import url('./common.css');
@import url('../components/header/header.css');
@import url('../components/sidebar/sidebar.css');
@import url('../components/pagination/pagination.css');
@import url('../components/creatable-select/creatable-select.css');
@import url('../containers/dashboard/dashboard.css');
/* Fonts  */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* common css */
body {
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px;
}

label {
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

.custom-input label {
  font-size: 18px;
  margin-bottom: 10px;
}

.custom-input label span {
  font-size: 14px;
}

.custom-input {
  margin-bottom: 30px;
  width: 50%;
  position: relative;
}

.react-tag-input {
  border: none;
  padding: 0;
}

.invalid-feedback {
  position: absolute;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
}

.custom-input textarea {
  width: 100%;
  padding: 25px 20px;
  border: 1px solid black;
  font-size: 16px;
}

.custom-input input {
  width: 100%;
  padding: 25px 20px;
  border: 1px solid black;
  font-size: 16px;
}

.custom-input input:focus {
  outline: none;
}

.form-control-file {
  padding: 10px 20px !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: black;
}

.custom-input select {
  height: 52px;
  border: 1px solid #000;
  font-size: 18px;
}

.hide {
  display: none;
}

.form-container {
  width: 100%;
  margin: 0px 30px;
  padding-bottom: 50px;
}

.video-list-container {
  margin: 0px 30px;
}

.list-container {
  margin: 0px 50px;
}

.video-card.customborder {
  border: 1px solid #000;
}

.video-card .video-list-img {
  width: 32%;
}

.video-card .video-list-img img {
  width: 100%;
  object-fit: cover;
  max-height: 196.77px;
}

.video {
  width: 32%;
}

.video-desc {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.show-reel-video-desc {
  justify-content: space-between;
}

.video-list-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.video-list-head h2 {
  font-size: 28px;
  text-transform: uppercase;

}

.video-card label {
  font-size: 20px;
  padding-bottom: 5px;
}

.video-desc label {
  font-size: 16px;
}

.video-desc label a {
  color: #000 !important;
  text-decoration: none;
}

.video-desc label a span {
  display: block;
}

.form-container .head {
  padding-bottom: 30px;
}

.form-container .head h4 {
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 5px;
}

.form-container .head p {
  font-size: 16px;
  font-weight: 300;
}

.common-container {
  margin-top: 150px;
}

.video-card {
  margin-bottom: 50px;
  cursor: pointer;
}


.common-wrapper {
  width: 83%;
  height: 100vh;
  margin-left: 17%;
  overflow-x: hidden;
}

.btn-section .black-btn {
  margin-left: 20px;
}

.videos-section {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px;
}

.videos-section .video {
  width: 48%;
  height: 245px;
}

.video-react-video:focus {
  outline: none !important;
}

.videos-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

textarea {
  resize: none;
}

.close-icon {
  position: absolute;
  top: -10px;
  z-index: 999;
  width: 30px;
  right: -10px;
  cursor: pointer;
}

.close-icon img {
  width: 100%;
}

.add-director .custom-input {
  width: 100%;
}

.add-director h2 {
  font-size: 24px;
  padding-bottom: 15px;
}

.modal-dialog {
  max-width: 450px;
  margin: 0px auto !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
  top: 50% !important;

}

.login-container {
  background-color: #f7f7fc;
  margin-top: 70px;
  height: calc(100vh - 70px);
}

.login-card .custom-input {
  width: 100%;
}

.login-card .btn {
  width: 100%;
}

.login-card {
  padding: 40px;
  width: 550px;
  background-color: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-card .head {
  padding-bottom: 40px;
}

.login-card .head h2 {
  font-size: 30px;
  padding-bottom: 10px;
}

.login-card .head h3 {
  font-size: 18px;
}
