.sidebar {
  width: 17%;
  height: 100vh;
  padding: 20px 0px;
  background-color: #000000;
  position: fixed;
  top: 0px;
}

.sidebar-logo {
  width: 140px;
  text-align: center;
  margin: 0 30px;
  margin-bottom: 80px;
}

.sidebar ul {
  list-style: none;
  margin: 0px;
  padding: 0px 30px;
}

.sidebar ul li {
  margin-bottom: 25px;
  cursor: pointer;
}


.sidebar ul li .head label {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.sidebar ul li .head-list {
  margin-top: 10px;
}

.sidebar ul li .head-list a {
  font-size: 16px;
  display: block;
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: none;
}

.sidebar ul li .head-list a.active {
  background-color: #fff;
  color: #263760;
}

/* .sidebar .head {
  padding: 0px 30px;
} */
.sidebar .head.list {
  padding: 12px 30px;
}

.sidebar ul li .head-list a:hover {
  background-color: #fff;
  color: #263760;
}

.sidebar ul li .head span {
  float: right;
}

.sidebar ul li .head span img {
  width: 15px;
}
