@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
/* Application level common styles */

body {
  background-color: #f9fbfd;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: transparent;
}

.btn-primary {
  background-color: transparent;
  border: 0px;
}

.btn-primary:hover {
  background-color: transparent;
  border-color: transparent;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus {
  border-color: transparent;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.red-btn {
  background-color: red;
  color: #fff;
  font-size: 18px;
  width: 100px;
  text-align: center;
}

.black-btn.transparent {
  background-color: transparent;
  color: #000;
}

.black-btn.transparent.red:hover {
  background-color: transparent !important;
  color: red !important;
}

.black-btn.transparent.red:active {
  background-color: transparent !important;
  color: red !important;
}

.black-btn.transparent.red:focus {
  background-color: transparent !important;
  color: red !important;
}

.black-btn.transparent.red {
  background-color: transparent !important;
  color: red !important;
}

.black-btn.transparent:hover {
  background-color: transparent !important;
  color: #000 !important;
}

.black-btn.transparent:active {
  background-color: transparent !important;
  color: #000 !important;
}

.black-btn.transparent:focus {
  background-color: transparent !important;
  color: #000 !important;
}

.red-btn:hover {
  background-color: red;
  color: #fff;
  font-size: 18px;
  width: 100px;
  text-align: center;
}

.red-btn:active {
  background-color: red;
  color: #fff;
  font-size: 18px;
  width: 100px;
  text-align: center;
}

.white {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}

.white:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}

.white:focus {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}

.bottom-btn {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.black-btn {
  background-color: #000;
  color: #fff;
  font-size: 18px;
  width: 100px;
  text-align: center;
}

.black-btn span {
  margin-left: 20px;
}

.black-btn span img {
  width: 25px;
}

.black-btn.width {
  width: 200px;
}

.black-btn.halfwidth {
  width: 150px;
}

.black-btn.fullwidth {
  width: 235px;
}

.black-btn.btn-primary:hover {
  background-color: #000;
  color: #fff;
}

.black-btn.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: #000;
  border-color: transparent;
}

.black-btn.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: #000;
  border-color: transparent;
}

.black-btn.btn-primary.focus,
.black-btn.btn-primary:focus {
  background-color: #000;
}

.video-react-control-bar {
  display: none;
}

.video-card-btn {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #000;
  padding: 20px;
}

.director-head {
  justify-content: space-between;
}

.video-react-big-play-button {
  display: none;
}

.video-react-controls-enabled {
  padding-top: 0px !important;
  position: relative;
}

.video-react-video {
  width: 100%;
}

.video-card-section {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.empty-state {
  margin-top: 20px;
  padding: 20px;
  background-color: black;
  text-align: center;
}

.empty-state p {
  font-size: 20px;
  color: #fff;
}

.video-card-text {
  padding: 20px;
  display: flex;
}

.video-card-text-list {
  width: 30%;
}

.video-card-text-list.width {
  width: 100%;
}

.video-card-text-list .brand-img {
  width: 150px;
}

.video-card-text-list .brand-img img {
  width: 100%;
}

.video-card-text h2 {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 5px;
}

.video-card-text h3 {
  font-size: 18px;
}

.m-l-20 {
  margin-left: 20px;
}

.director-list {
  display: flex;
  align-items: center;
}

.director-list {
  margin-bottom: 20px;
}

.director-list p {
  font-size: 22px;
  width: 20%;
}

.director-list .black-btn {
  margin-left: 10px;
}

.award-logo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: grey;
}

.award-logo .video-card {
  width: 50%;
  margin: 25px auto;
}

.award-logo .video-card .video-list-img {
  width: 100%;
}

.del-button {
  text-align: center;
}

.awards .btn-section {
  text-align: center;
  margin: 0px auto;
}

.awards .btn-section .black-btn {
  margin-left: 0px;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play svg {
  fill: #fff;
}

.show-real .btn-section {
  width: 71%;
  margin-top: 30px;
}

.video-card-section.showreal {
  justify-content: unset;
}

.video-card-section.showreal .video-list-img {
  margin-right: 20px;
}

.showreal-text {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.showreal-text h2 {
  font-size: 26px;
  padding-bottom: 10px;
}

.back-arrow {
  width: 35px;
  margin-top: -60px;
  margin-bottom: 20px;
  cursor: pointer;
}

.back-arrow img {
  width: 100%;
}

.image-thumbnail {
  width: 300px;
  padding-top: 20px;
  position: relative;
}

.image-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-close {
  position: absolute;
  display: inline-block;
  right: -18px;
  width: 30px;
  top: 43px;
  cursor: pointer;
  z-index: 99;
}

.required-file input,
.invalid-email {
  border-color: #dc3545 !important;
}

.required-file-feedback,
.required-input-feedback {
  width: 100% !important;
  margin-top: .25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
  position: absolute;
}

.start-required {
  color: red;
  padding-left: 3px;
  font-size: 18px !important;
}

/* Header styles */
.header-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px 0px;
  margin: -1px 30px;
  border-bottom: 1px solid #e3ebf6;
}

.header.login {
  width: 100%;
  background-color: #000;
  margin-left: 0;
  overflow-x: hidden;
}

.login .header-img {
  width: 150px;
}

.login .header-img img {
  width: 100%;
}

.header .navbar-brand {
  width: 150px;
  padding: 0px;
}

.header .arrow {
  display: inline-block;
  margin-left: 10px;
}

.header .arrow img {
  width: 12px;
  transform: rotate(90deg);
}

.header .navbar-brand img {
  width: 100%;
}

.header {
  /* box-shadow: 0 1px 3px 0 #eef4ff; */
  position: fixed;
  top: 0px;
  width: 83%;
  z-index: 99;
  background-color: #fff;

  margin-left: 17%;
  overflow: hidden;
}

.header .navbar {
  padding: 0px;
}

.header .dropdown-menu {
  min-width: auto !important;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px !important;
  border: 0px;
}

.header .dropdown-item {
  padding: 0px 20px;
}

.header .dropdown-item:hover {
  background-color: transparent;
}

.header-text h2 {
  font-size: 18px;
}

.header-text h1 {
  font-size: 28px;
}

.admin-text {
  color: #000;
}


.header-nav {
  margin-right: 30px;
  cursor: pointer;
  text-decoration: none !important;
  color: #000 !important;
}

.header-nav:hover {
  border-bottom: 2px solid #000000;
}

.header-nav.active {
  border-bottom: 2px solid #000000;
  color: #000000 !important;
  font-size: 16px;
}

.sidebar {
  width: 17%;
  height: 100vh;
  padding: 20px 0px;
  background-color: #000000;
  position: fixed;
  top: 0px;
}

.sidebar-logo {
  width: 140px;
  text-align: center;
  margin: 0 30px;
  margin-bottom: 80px;
}

.sidebar ul {
  list-style: none;
  margin: 0px;
  padding: 0px 30px;
}

.sidebar ul li {
  margin-bottom: 25px;
  cursor: pointer;
}


.sidebar ul li .head label {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.sidebar ul li .head-list {
  margin-top: 10px;
}

.sidebar ul li .head-list a {
  font-size: 16px;
  display: block;
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: none;
}

.sidebar ul li .head-list a.active {
  background-color: #fff;
  color: #263760;
}

/* .sidebar .head {
  padding: 0px 30px;
} */
.sidebar .head.list {
  padding: 12px 30px;
}

.sidebar ul li .head-list a:hover {
  background-color: #fff;
  color: #263760;
}

.sidebar ul li .head span {
  float: right;
}

.sidebar ul li .head span img {
  width: 15px;
}

.pagination-container {
  position: fixed;
  bottom: 20px;
  right: 60px;
  background: #fff;
  box-shadow: 0 1px 3px 0 #EEF4FF;
  margin: 0 30px;
}

.page-link-disable .page-link {
  color: #ddd !important;
}

.page-link-disable .page-link:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.pagination .page-link {
  color: #000;
  padding: .8rem 1.05rem;
}

.pagination .page-item.active .page-link {
  background-color: #000;
  border-color: #000;
}

.pagination .page-link:focus {
  box-shadow: none;
}

.css-g1d714-ValueContainer:focus,
.css-2b097c-container:focus,
.css-yk16xz-control:focus,
.css-1pahdxg-control,
.css-1pahdxg-control:hover {
  border: 1px solid #000000 !important;
  box-shadow: none !important;
  outline: none !important;
}

.css-1pahdxg-control:hover {
  border-color: #000000 !important;
}

.css-yk16xz-control {
  border: 1px solid #000000 !important;
}

.css-g1d714-ValueContainer {
  padding: 9.5px 20px !important;
}

.custom-class .css-yk16xz-control {
  border: 1px solid red !important;
}

.dashboard-container {
  margin: 0 auto;
}

.c-dashboardInfo {
  margin-bottom: 20px;
}

.c-dashboardInfo a {
  color: inherit;
}

.c-dashboardInfo a:hover {
  text-decoration: none;
  color: inherit;
}

.c-dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 40px 25px 20px;
  height: 100%;
  cursor: pointer;
}

.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  color: #6c6c6c;
  font-size: 1.18em;
}

.c-dashboardInfo span {
  display: block;
}

.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 2.5em;
  line-height: 64px;
  color: #323c43;
}

.c-dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: '';
}

.c-dashboardInfo:nth-child(odd) .wrap:after {
  background: linear-gradient(82.59deg, #000 0%, #00008B 100%);
}

.c-dashboardInfo:nth-child(even) .wrap:after {
  background: linear-gradient(82.59deg, #00008B 0%, #000000 100%);
}

.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}

.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-shrink: 0;
}

/* Custom CSS Dependencies */
/* Fonts  */

/* common css */
body {
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px;
}

label {
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

.custom-input label {
  font-size: 18px;
  margin-bottom: 10px;
}

.custom-input label span {
  font-size: 14px;
}

.custom-input {
  margin-bottom: 30px;
  width: 50%;
  position: relative;
}

.react-tag-input {
  border: none;
  padding: 0;
}

.invalid-feedback {
  position: absolute;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
}

.custom-input textarea {
  width: 100%;
  padding: 25px 20px;
  border: 1px solid black;
  font-size: 16px;
}

.custom-input input {
  width: 100%;
  padding: 25px 20px;
  border: 1px solid black;
  font-size: 16px;
}

.custom-input input:focus {
  outline: none;
}

.form-control-file {
  padding: 10px 20px !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: black;
}

.custom-input select {
  height: 52px;
  border: 1px solid #000;
  font-size: 18px;
}

.hide {
  display: none;
}

.form-container {
  width: 100%;
  margin: 0px 30px;
  padding-bottom: 50px;
}

.video-list-container {
  margin: 0px 30px;
}

.list-container {
  margin: 0px 50px;
}

.video-card.customborder {
  border: 1px solid #000;
}

.video-card .video-list-img {
  width: 32%;
}

.video-card .video-list-img img {
  width: 100%;
  object-fit: cover;
  max-height: 196.77px;
}

.video {
  width: 32%;
}

.video-desc {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.show-reel-video-desc {
  justify-content: space-between;
}

.video-list-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.video-list-head h2 {
  font-size: 28px;
  text-transform: uppercase;

}

.video-card label {
  font-size: 20px;
  padding-bottom: 5px;
}

.video-desc label {
  font-size: 16px;
}

.video-desc label a {
  color: #000 !important;
  text-decoration: none;
}

.video-desc label a span {
  display: block;
}

.form-container .head {
  padding-bottom: 30px;
}

.form-container .head h4 {
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 5px;
}

.form-container .head p {
  font-size: 16px;
  font-weight: 300;
}

.common-container {
  margin-top: 150px;
}

.video-card {
  margin-bottom: 50px;
  cursor: pointer;
}


.common-wrapper {
  width: 83%;
  height: 100vh;
  margin-left: 17%;
  overflow-x: hidden;
}

.btn-section .black-btn {
  margin-left: 20px;
}

.videos-section {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px;
}

.videos-section .video {
  width: 48%;
  height: 245px;
}

.video-react-video:focus {
  outline: none !important;
}

.videos-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

textarea {
  resize: none;
}

.close-icon {
  position: absolute;
  top: -10px;
  z-index: 999;
  width: 30px;
  right: -10px;
  cursor: pointer;
}

.close-icon img {
  width: 100%;
}

.add-director .custom-input {
  width: 100%;
}

.add-director h2 {
  font-size: 24px;
  padding-bottom: 15px;
}

.modal-dialog {
  max-width: 450px;
  margin: 0px auto !important;
  transform: translateY(-50%) !important;
  top: 50% !important;

}

.login-container {
  background-color: #f7f7fc;
  margin-top: 70px;
  height: calc(100vh - 70px);
}

.login-card .custom-input {
  width: 100%;
}

.login-card .btn {
  width: 100%;
}

.login-card {
  padding: 40px;
  width: 550px;
  background-color: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-card .head {
  padding-bottom: 40px;
}

.login-card .head h2 {
  font-size: 30px;
  padding-bottom: 10px;
}

.login-card .head h3 {
  font-size: 18px;
}

