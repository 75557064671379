/* Application level common styles */

body {
  background-color: #f9fbfd;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: transparent;
}

.btn-primary {
  background-color: transparent;
  border: 0px;
}

.btn-primary:hover {
  background-color: transparent;
  border-color: transparent;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus {
  border-color: transparent;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.red-btn {
  background-color: red;
  color: #fff;
  font-size: 18px;
  width: 100px;
  text-align: center;
}

.black-btn.transparent {
  background-color: transparent;
  color: #000;
}

.black-btn.transparent.red:hover {
  background-color: transparent !important;
  color: red !important;
}

.black-btn.transparent.red:active {
  background-color: transparent !important;
  color: red !important;
}

.black-btn.transparent.red:focus {
  background-color: transparent !important;
  color: red !important;
}

.black-btn.transparent.red {
  background-color: transparent !important;
  color: red !important;
}

.black-btn.transparent:hover {
  background-color: transparent !important;
  color: #000 !important;
}

.black-btn.transparent:active {
  background-color: transparent !important;
  color: #000 !important;
}

.black-btn.transparent:focus {
  background-color: transparent !important;
  color: #000 !important;
}

.red-btn:hover {
  background-color: red;
  color: #fff;
  font-size: 18px;
  width: 100px;
  text-align: center;
}

.red-btn:active {
  background-color: red;
  color: #fff;
  font-size: 18px;
  width: 100px;
  text-align: center;
}

.white {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}

.white:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}

.white:focus {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}

.bottom-btn {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.black-btn {
  background-color: #000;
  color: #fff;
  font-size: 18px;
  width: 100px;
  text-align: center;
}

.black-btn span {
  margin-left: 20px;
}

.black-btn span img {
  width: 25px;
}

.black-btn.width {
  width: 200px;
}

.black-btn.halfwidth {
  width: 150px;
}

.black-btn.fullwidth {
  width: 235px;
}

.black-btn.btn-primary:hover {
  background-color: #000;
  color: #fff;
}

.black-btn.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: #000;
  border-color: transparent;
}

.black-btn.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: #000;
  border-color: transparent;
}

.black-btn.btn-primary.focus,
.black-btn.btn-primary:focus {
  background-color: #000;
}

.video-react-control-bar {
  display: none;
}

.video-card-btn {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #000;
  padding: 20px;
}

.director-head {
  justify-content: space-between;
}

.video-react-big-play-button {
  display: none;
}

.video-react-controls-enabled {
  padding-top: 0px !important;
  position: relative;
}

.video-react-video {
  width: 100%;
}

.video-card-section {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.empty-state {
  margin-top: 20px;
  padding: 20px;
  background-color: black;
  text-align: center;
}

.empty-state p {
  font-size: 20px;
  color: #fff;
}

.video-card-text {
  padding: 20px;
  display: flex;
}

.video-card-text-list {
  width: 30%;
}

.video-card-text-list.width {
  width: 100%;
}

.video-card-text-list .brand-img {
  width: 150px;
}

.video-card-text-list .brand-img img {
  width: 100%;
}

.video-card-text h2 {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 5px;
}

.video-card-text h3 {
  font-size: 18px;
}

.m-l-20 {
  margin-left: 20px;
}

.director-list {
  display: flex;
  align-items: center;
}

.director-list {
  margin-bottom: 20px;
}

.director-list p {
  font-size: 22px;
  width: 20%;
}

.director-list .black-btn {
  margin-left: 10px;
}

.award-logo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: grey;
}

.award-logo .video-card {
  width: 50%;
  margin: 25px auto;
}

.award-logo .video-card .video-list-img {
  width: 100%;
}

.del-button {
  text-align: center;
}

.awards .btn-section {
  text-align: center;
  margin: 0px auto;
}

.awards .btn-section .black-btn {
  margin-left: 0px;
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.play svg {
  fill: #fff;
}

.show-real .btn-section {
  width: 71%;
  margin-top: 30px;
}

.video-card-section.showreal {
  justify-content: unset;
}

.video-card-section.showreal .video-list-img {
  margin-right: 20px;
}

.showreal-text {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.showreal-text h2 {
  font-size: 26px;
  padding-bottom: 10px;
}

.back-arrow {
  width: 35px;
  margin-top: -60px;
  margin-bottom: 20px;
  cursor: pointer;
}

.back-arrow img {
  width: 100%;
}

.image-thumbnail {
  width: 300px;
  padding-top: 20px;
  position: relative;
}

.image-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-close {
  position: absolute;
  display: inline-block;
  right: -18px;
  width: 30px;
  top: 43px;
  cursor: pointer;
  z-index: 99;
}

.required-file input,
.invalid-email {
  border-color: #dc3545 !important;
}

.required-file-feedback,
.required-input-feedback {
  width: 100% !important;
  margin-top: .25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
  position: absolute;
}

.start-required {
  color: red;
  padding-left: 3px;
  font-size: 18px !important;
}
