/* Header styles */
.header-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 15px 0px;
  margin: -1px 30px;
  border-bottom: 1px solid #e3ebf6;
}

.header.login {
  width: 100%;
  background-color: #000;
  margin-left: 0;
  overflow-x: hidden;
}

.login .header-img {
  width: 150px;
}

.login .header-img img {
  width: 100%;
}

.header .navbar-brand {
  width: 150px;
  padding: 0px;
}

.header .arrow {
  display: inline-block;
  margin-left: 10px;
}

.header .arrow img {
  width: 12px;
  transform: rotate(90deg);
}

.header .navbar-brand img {
  width: 100%;
}

.header {
  /* box-shadow: 0 1px 3px 0 #eef4ff; */
  position: fixed;
  top: 0px;
  width: 83%;
  z-index: 99;
  background-color: #fff;

  margin-left: 17%;
  overflow: hidden;
}

.header .navbar {
  padding: 0px;
}

.header .dropdown-menu {
  min-width: auto !important;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px !important;
  border: 0px;
}

.header .dropdown-item {
  padding: 0px 20px;
}

.header .dropdown-item:hover {
  background-color: transparent;
}

.header-text h2 {
  font-size: 18px;
}

.header-text h1 {
  font-size: 28px;
}

.admin-text {
  color: #000;
}


.header-nav {
  margin-right: 30px;
  cursor: pointer;
  text-decoration: none !important;
  color: #000 !important;
}

.header-nav:hover {
  border-bottom: 2px solid #000000;
}

.header-nav.active {
  border-bottom: 2px solid #000000;
  color: #000000 !important;
  font-size: 16px;
}
