.dashboard-container {
  margin: 0 auto;
}

.c-dashboardInfo {
  margin-bottom: 20px;
}

.c-dashboardInfo a {
  color: inherit;
}

.c-dashboardInfo a:hover {
  text-decoration: none;
  color: inherit;
}

.c-dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 40px 25px 20px;
  height: 100%;
  cursor: pointer;
}

.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  color: #6c6c6c;
  font-size: 1.18em;
}

.c-dashboardInfo span {
  display: block;
}

.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 2.5em;
  line-height: 64px;
  color: #323c43;
}

.c-dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: '';
}

.c-dashboardInfo:nth-child(odd) .wrap:after {
  background: linear-gradient(82.59deg, #000 0%, #00008B 100%);
}

.c-dashboardInfo:nth-child(even) .wrap:after {
  background: linear-gradient(82.59deg, #00008B 0%, #000000 100%);
}

.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}

.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}
