.pagination-container {
  position: fixed;
  bottom: 20px;
  right: 60px;
  background: #fff;
  box-shadow: 0 1px 3px 0 #EEF4FF;
  margin: 0 30px;
}

.page-link-disable .page-link {
  color: #ddd !important;
}

.page-link-disable .page-link:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.pagination .page-link {
  color: #000;
  padding: .8rem 1.05rem;
}

.pagination .page-item.active .page-link {
  background-color: #000;
  border-color: #000;
}

.pagination .page-link:focus {
  box-shadow: none;
}
